/**
 * CONTENTS
 * --------
 * LINKS
 * IMAGE LINK WRAPPER (FOR PRINT)
 */
 
/**
 * --------------------------------------------------------------------------------------------------------------------
 * --------------------------------------------------------------------------------------------------------------------
 * LINKS
 * --------------------------------------------------------------------------------------------------------------------
 * --------------------------------------------------------------------------------------------------------------------
 */

// force external links that start with https and https that do not have the site base url to open in a new tab
var baseUrl = document.location.origin;
$('a[href^="http://"], a[href^="https://"]').not('a[href^="' + baseUrl + '"]').attr('target', '_blank');

// add 'noopener noreferrer' to any link with target="_blank"
$('a[target="_blank"]').attr('rel', 'noopener noreferrer');

/**
 * --------------------------------------------------------------------------------------------------------------------
 * --------------------------------------------------------------------------------------------------------------------
 * IMAGE LINK WRAPPER (FOR PRINT)
 * --------------------------------------------------------------------------------------------------------------------
 * --------------------------------------------------------------------------------------------------------------------
 */

// add class to hide url of image link (found within specific container) from print
// see _print.scss
$('.js-main-content a > img').each(function () {

  $(this).parent().addClass('u-image-link');

});